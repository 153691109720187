import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const KiczkiKosciolMuzeumParafialne = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Kiczki - Kościół i Muzeum Parafialne w gminie Cegłów" />
      <ReturnToPrevPage to={'/gminy/ceglow'}>
        Materiały dotyczące gminy Cegłów
      </ReturnToPrevPage>
      <Header1>Kiczki - Kościół i Muzeum Parafialne</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <VideoPlayer videoId={483070697} />
        <VideoPlayer videoId={895166445} />
        <p>
          Należy przyznać, że świątynia zbudowana na niewielkim wzniesieniu i
          otoczona kamiennym murem robi duże wrażenie. Jest ona drugą, znaną
          nam, znajdującą się na terenie miejscowości. Pierwsza z nich,
          istniejąca od 1626 roku, została spalona siedemdziesiąt pięć lat
          później. Przy okazji zauważmy, że parafia w Kiczkach została erygowana
          w 1458 roku przez biskupa poznańskiego Andrzeja z Bnina Opalińskiego.
          Obecny kościół, został wzniesiony w 1751 roku. Na zlokalizowany przed
          nim plac prowadzi brama pełniąca również rolę dzwonnicy. W tym
          wysokim, murowanym, obiekcie zawieszono dwa dzwony noszące imiona Anna
          i Maria. Przed samym sakralnym budynkiem zwraca uwagę natomiast ganek
          wsparty na dębowych kolumnach wykonanych w stylu toskańskim. Równie
          okazale prezentuje się wnętrze świątyni. Dominujące w nim odcienie
          bieli i złocenia interesująco kontrastują z brązową kolorystyką
          elewacji zewnętrznej zabytkowego obiektu. Zaciekawienie osób
          odwiedzających go wzbudzają przechowywane w nim pamiątkowe przedmioty.
          Do najcenniejszych z nich wypada zaliczyć dzwon o imieniu Antoni,
          który został odlany w Gdańsku w 1751 roku. Co ciekawe służył on do
          przełomu tysiącleci. Pieczołowicie chronione są też ornaty.
        </p>
        <p>
          Jeden z nich, jedwabny, został wykonany na bazie szlacheckich pasów.
          Drugi prezentuje Świętą Annę z Matką Boską. Oba te elementy stroju
          charakterystycznego dla osoby duchownej umieszczono w gablotach
          zapewniających im bezpieczeństwo. Istotną częścią wystroju kościoła są
          również znajdujące się po obu stronach ołtarza głównego figury
          przedstawiające anioły. Zostały one ufundowane przez jedno z
          parafialnych małżeństw z okazji pięćdziesiątej rocznicy ślubu.
          Pomiędzy nimi nie sposób nie dostrzec obrazu, na którym uwieczniono
          świętą rodzinę. Namalowany w osiemnastym stuleciu został
          odrestaurowany w 1961 roku przez Henryka Kucharskiego. Artystyczne
          dokonania pokazujące postacie błogosławionych i świętych osób zdobią
          natomiast ściany sakralnego obiektu. Wśród nich można ujrzeć
          wyobrażenia takich postaci jak np. św. Krzysztof z Dzieciątkiem, św.
          Ignacy Loyola oraz św. Rozalia.
        </p>
        <p>
          W świątyni nie brakuje ponadto przykładów sztuki ludowej. Należy do
          nich choćby dwumetrowy krucyfiks umieszczony w prezbiterium.
          Dziewiętnastowieczne pochodzenie posiadają tymczasem dwa konfesjonały
          oraz sześciogłosowy prospekt organowy. W 1993 roku został on odnowiony
          staraniem proboszcza i parafian. Kościelne wnętrze wzbogacone jest też
          poprzez umieszczenie w nim m.in. „graduszek” czyli nakrycia
          drewnianych balustrad, licznych wotów, sygnaturki oraz kryształowych
          żyrandoli. – Drewniany, konstrukcji zrębowej wzmocnionej lisicami,
          oszalowany. Nawa na planie prostokąta, prezbiterium węższe, zamknięte
          ścianą prostą z dwiema zakrystiami od południa i północy. Wewnątrz
          sklepienie pozorne o łuku odcinkowym. W zakrystii północnej strop;
          drzwi klasycystyczne ze skośnie układanych klepek z dekoracją z palmet
          1 ćw. w. XIX. Dach dwuspadowy, kryty gontem – napisały w 1968 roku
          opisując walory architektoniczne kościoła Izabella Galicka i Hanna
          Sygietyńska.
        </p>
        <p>
          Warto dodać, że w 1824 i 1860 roku w świątyni przeprowadzono prace
          renowacyjne. Z kolei 1901 rok przyniósł dokonanie jej gruntownego
          remontu oraz powiększenia. Przedłużono wówczas jej nawę ku zachodowi.
          Dobudowano od południowej strony zakrystię i galerię znajdującą się
          nad nią. W ramach prac powstały również ołtarze boczne. W okresie
          powojennym m.in. zelektryfikowano kościół i plebanię (1958),
          wymieniono pokrycie dachowe z gontu na blachę ocynkowaną (1975 rok),
          zainstalowano urządzenia radiofoniczne (1981) oraz m.in. wymieniono
          główne drzwi wejściowe i deski szalunkowe (2003). Współczesne nam
          czasy zaowocowały natomiast ułożeniem wokół kościoła kamiennej, w tym
          granitowej i sporządzonej z kamieni polnych kostki. Sakralny obiekt
          zyskał ponadto iluminację, której elementami stały się podścienne
          lampy i naświetlacze. Dodajmy, że obecnym proboszczem parafii jest
          ksiądz Andrzej Sulej. Informacje dotyczące jej przeszłości i
          teraźniejszości można znaleźć na stronie internetowej –
          <a href="www.parafiakiczki.pl" target="_blank" rel="nofollow">
            www.parafiakiczki.pl
          </a>
          .
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/ceglow/kiczki-kosciol-i-muzeum-parafialne/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(
      filter: {
        relativePath: { regex: "/ceglow/kiczki-kosciol-i-muzeum-parafialne/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default KiczkiKosciolMuzeumParafialne
